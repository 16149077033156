<template>
  <div class="vuse-content-wrapper">
    <v-row no-gutters justify="space-between" align="center">
      <smart-breadcrumbs :items="navigationElements" />
      <help class="help--breadcrumb" :helpId="44" />
    </v-row>
    <v-card>
      <process-tree />
    </v-card>
  </div>
</template>

<script>
  import ProcessTree from '@/components/HumanTalent/ProcessTree'

  export default {
    components: { ProcessTree },
    name: 'Process',
    data() {
      return {
        navigationElements: [
          {
            text: 'Talento Humano',
            disabled: false,
            query: null,
            params: null,
            name: 'administrative/human-talent/AdminPanel',
            href: '/administrative/human-talent/admin-panel',
          },
          {
            text: 'Inventario de Procesos',
            disabled: true,
          },
        ],
      }
    },
  }
</script>
<style lang="scss"></style>
