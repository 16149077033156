<template>
  <v-card class="neu-glow process-tree">
    <v-card-title class="primary white--text text-h6" style="padding: 8px">
      <v-row class="mx-0">
        Inventario de Procesos
        <v-spacer />
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              small
              v-on="on"
              color="#adadb0"
              dark
              @click.prevent="handleCreate()"
              >Crear
            </v-btn>
          </template>
          <span>Crear Proceso</span>
        </v-tooltip>
      </v-row>
    </v-card-title>
    <v-row class="mx-0" justify="space-between">
      <v-col md="5" cols="12" class="mt-2">
        <v-text-field
          v-model="search"
          label="Buscar"
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
        />
        <v-treeview
          dense
          :active.sync="active"
          :items="items"
          :load-children="getChildrenProcess"
          :open.sync="open"
          :search="search"
          :filter="filter"
          shaped
          hoverable
          activatable
          :return-object="true"
          color="primary"
          selected-color="primary"
          open-on-click
          transition
          @update:active="getSelectedProcess($event)"
        >
          <template v-slot:prepend="{ item }">
            <v-menu offset-y class="options-menu" open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon x-small v-bind="attrs" v-on="on"
                  ><v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item class="px-1" @click="handleEdit(item)">
                  <v-list-item-icon style="margin: 0px">
                    <v-btn class="ma-2" x-small inline fab color="primary"
                      ><v-icon>mdi-pencil-outline</v-icon></v-btn
                    >
                  </v-list-item-icon>
                  <v-list-item-content style="padding: 0px">
                    Editar Proceso
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-1" @click="deleteItem(item)">
                  <v-list-item-icon style="margin: 0px">
                    <v-btn class="ma-2" x-small inline fab color="red"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </v-list-item-icon>
                  <v-list-item-content style="padding: 0px">
                    Eliminar Proceso
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <!-- <template v-slot:label="{ item }">
            {{ item.name }}
          </template> -->
          <!-- <template v-slot:append="{ item }">
            <v-btn x-small class="ml-2" rounded :color="item.model | toModelColor">{{
              item.model | toModelName
            }}</v-btn>
          </template> -->
        </v-treeview>
      </v-col>

      <v-col md="7" cols="12" class="d-flex">
        <v-divider vertical />
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selected.name"
            class="text-h6 grey--text text--lighten-1 font-weight-light"
            style="align-self: center"
          >
            <v-card-text><h3>Seleccione un proceso</h3> </v-card-text>
          </div>
          <v-card v-else :key="selected.id" class="mx-auto py-4" width="100%" flat>
            <div v-if="selected.lastContent" :key="selectedIndex">
              <v-card-text style="padding-top: 0px">
                <h3 class="mb-2">
                  {{ selected.name }}
                </h3>
                <iframe
                  v-if="selected.lastContent && selected.lastContent.route"
                  :src="
                    'https://docs.google.com/gview?url=' +
                    selected.lastContent.route +
                    '&embedded=true'
                  "
                  style="width: 100%; height: 500px"
                  frameborder="0"
                />
              </v-card-text>
              <v-subheader
                >Última modificación: {{ selected.updated_at.split('T')[0] }}</v-subheader
              >
              <v-subheader>Cargo: {{ selected.position.name }}</v-subheader>
              <v-subheader v-if="selected.children & selected.children.name"
                >Proceso padre: {{ selected.children.name }}</v-subheader
              >
            </div>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
    <v-row justify="center">
      <modal name="crudModal" :height="'80%'" :width="'90%'" :adaptive="true">
        <v-card class="pa-4" elevation="0">
          <h2 class="text-center mb-4" v-if="flow === 'create'">Crear Proceso</h2>
          <h2 class="text-center mb-4" v-if="flow === 'edit'">Editar Proceso</h2>

          <span v-if="flow === 'edit' && item.created_at"
            >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
            {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
          >
          <v-form ref="formCompany" v-model="valid" style="width: 100%">
            <v-row>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-row style="margin: 0px">
                  <label style="margin: 0px auto; font-size: 13px; width: 30%"
                    >Proceso padre</label
                  >
                  <multiselect
                    style="width: 70%"
                    track-by="id"
                    label="name"
                    placeholder="Seleccionar"
                    v-model="processId"
                    :options="processes"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" md="6">
                <v-row style="margin: 0px">
                  <label style="margin: 0px auto; font-size: 13px; width: 20%"
                    >Cargo</label
                  >
                  <multiselect
                    style="width: 80%"
                    track-by="id"
                    label="name"
                    placeholder="Seleccionar"
                    v-model="positionId"
                    :options="positions"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" md="6">
                <v-text-field
                  counter
                  required
                  :rules="[rules.required, rules.max]"
                  label="Nombre"
                  v-model="name"
                />
              </v-col>
              <v-col cols="12" lg="4" md="6">
                <v-text-field
                  counter
                  required
                  :rules="[rules.required, rules.max]"
                  label="Descripción"
                  v-model="description"
                />
              </v-col>
              <v-col cols="12" lg="4" md="6">
                <v-row style="margin: 0px">
                  <label style="margin: 0px auto; font-size: 13px; width: 20%"
                    >Tipo</label
                  >
                  <multiselect
                    style="width: 80%"
                    track-by="id"
                    label="name"
                    placeholder="Seleccionar"
                    v-model="type"
                    :options="contentTypes"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" md="6">
                <v-text-field
                  counter
                  required
                  :rules="[rules.required]"
                  label="Fecha"
                  v-model="date"
                  type="date"
                />
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-row style="margin: 0px">
                  <label style="margin: 0px auto; font-size: 13px; width: 20%"
                    >Formato</label
                  >
                  <multiselect
                    style="width: 80%"
                    track-by="id"
                    label="name"
                    placeholder="Seleccionar"
                    v-model="format"
                    :options="contentFormats"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-file-input
                  v-model="file"
                  chips
                  :rules="[rules.fileSize]"
                  ref="file"
                  show-size
                  :accept="fileAcepted"
                  :label="'Seleccione un archivo'"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <v-col cols="12" style="margin-bottom: 20px; height: 10%">
          <div class="row">
            <v-btn
              style="margin: auto; margin-top: 10px"
              v-if="flow === 'create'"
              :disabled="!valid"
              color="primary"
              @click="createItem()"
            >
              Crear
            </v-btn>
            <v-btn
              style="margin: auto; margin-top: 10px"
              v-if="flow === 'edit'"
              :disabled="!valid"
              color="primary"
              @click="saveItem()"
            >
              Guardar
            </v-btn>

            <v-btn
              small
              style="margin: auto; margin-top: 10px"
              color="primary"
              @click="reset"
            >
              Limpiar
            </v-btn>
            <v-btn
              style="margin: auto; margin-top: 10px"
              color="primary"
              @click="$modal.hide('crudModal')"
            >
              Cancelar
            </v-btn>
          </div>
        </v-col>
      </modal>
    </v-row>
  </v-card>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { decryptData } from '@/utils/encryption'
  import { toLocaleDateString } from '@/utils/helpers'
  const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  export default {
    data() {
      return {
        config: {},
        flow: '',
        flowContent: '',
        selectedIndex: 1,
        tab: 0,
        action: 0,
        active: [],
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        positions: [{ id: 0, name: 'No posee' }],
        processTree: [],
        processes: [],
        versions: [],
        dialog: false,
        valid: true,
        validContent: true,
        open: [],
        item: {},
        selected: {},
        orderBy: { id: 3, name: 'Descripción', order: 'description', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Descripción', order: 'description', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        search: null,
        caseSensitive: false,
        name: '',
        description: '',
        type: '',
        format: '',
        positionId: null,
        processId: { id: 0, name: 'Ninguna asignación' },
        users: [],
        url: '',
        date: new Date().toISOString().split('T')[0],
        file: null,
        contentFormats: [
          { id: 'xlsx', name: 'Hoja de Calculo' },
          { id: 'docx', name: 'Documento de texto' },
          { id: 'pdf', name: 'PDF' },
        ],
        contentTypes: [
          { id: 1, name: 'Manual' },
          { id: 2, name: 'Proceso' },
          { id: 3, name: 'Procedimiento' },
        ],
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 100 : true) || 'Debe poser menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => (v ? this.isURL(v) : true) || 'La URL es inválida',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    created() {
      this.getDefaultData()
      this.getProcesses()
      this.getPositions()
    },

    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
      items() {
        return this.processTree
      },
      filter() {
        return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
      },
      fileAcepted() {
        if (!this.format || !this.format.id) return ''

        switch (this.format.id) {
          case 'xlsx':
            return '.xls, .xlsx'

          case 'docx':
            return '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'

          case 'pdf':
            return '.pdf'

          default:
            return ''
        }
      },
    },
    filters: {
      toModelName(modelString) {
        if (!modelString) return ''

        switch (modelString) {
          case 'functional_area':
            return 'Área Funcional'
          case 'position':
            return 'Cargo'
          case 'process':
            return 'Proceso'
          default:
            return ''
        }
      },
      toModelColor(modelString) {
        if (!modelString) return ''

        switch (modelString) {
          case 'functional_area':
            return '#009ada'
          case 'position':
            return '#72c6ee'
          case 'process':
            return '#c3c5ca'
          default:
            return ''
        }
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,
      isURL(str) {
        let url
        try {
          url = new URL(str)
        } catch (_) {
          return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
      },
      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },
      async getDefaultData() {
        let searchStr = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.search && this.search !== '') {
          searchStr = '&search=' + this.search
        }
        this.$axios
          .get('processes?process_id=0&children=1' + searchStr + orderString)
          .then((response) => {
            this.processTree = response.data
            this.processTree.forEach((process) => {
              if (process.children && process.children.length === 0) {
                delete process.children
              } else if (process.children && process.children.length > 0) {
                process.children.forEach((process2) => {
                  if (process2.children && process2.children.length === 0) {
                    delete process2.children
                  } else if (process2.children && process2.children.length > 0) {
                    process2.children.forEach((process3) => {
                      if (process3.children && process3.children.length === 0) {
                        delete process3.children
                      }
                    })
                  }
                })
              }
            })
          })
          .catch((err) => console.warn(err))
      },

      async getChildrenProcess(item) {
        if (item.has_children) {
          await pause(2000)
          return this.$axios
            .get('processes?children=1&process_id=' + item.id)
            .then((response) => {
              item.children.push(...response.data)
              item.chindren.forEach((process) => {
                if (process.children && process.children.length === 0) {
                  delete process.children
                } else if (process.children && process.children.length > 0) {
                  process.children.forEach((process2) => {
                    if (process2.children && process2.children.length === 0) {
                      delete process2.children
                    }
                  })
                }
              })
            })
            .catch((err) => console.warn(err))
        }
      },
      getSelectedProcess(process) {
        if (process.length > 0) {
          this.$axios.get('processes/' + process[0].id).then((response) => {
            this.selected = response.data
            this.selected.lastContent =
              this.selected.uploads.length > 0 ? this.selected.uploads[0] : {}
          })
        }
      },
      async getProcesses(search) {
        let searchStr = ''
        if (search) {
          searchStr = '&search=' + search
        }
        this.$axios
          .get('processes?order=description&by=asc&limit=100' + searchStr)
          .then((response) => {
            this.processes = this.processes.concat(response.data)
          })
      },
      async getPositions(search) {
        let searchStr = ''
        if (search) {
          searchStr = '&search=' + search
        }
        this.$axios
          .get(
            'positions?order=name&by=asc&limit=100&type_id=' +
              this.company.type_id +
              searchStr
          )
          .then((response) => {
            this.positions = this.positions.concat(response.data)
          })
      },
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getDefaultData()
      },
      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.name = ''
        this.description = ''
        this.date = new Date().toISOString().split('T')[0]
        this.format = ''
        this.type = ''
        this.positionId = null
        this.processId = { id: 0, name: 'Ninguna asignación' }
        this.file = null

        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.name = this.item.name
        this.description = this.item.description
        this.positionId = this.positions.find(
          (manual) => manual.id === this.item.position_id
        )
        this.processId = this.processes.find(
          (process) => process.id === this.item.process_id
        )
        this.format = this.contentFormats.find((format) => format.id === this.item.format)
        this.type = this.contentTypes.find((type) => type.id === this.item.type)
        this.createdAt = new Date(this.item.created_at)
        this.date = new Date(this.item.date).toISOString().split('T')[0]
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('crudModal')
      },
      async createItem() {
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + decryptData(this.$session.get('tokenSession'))
            : 'Bearer ',
        }
        const formData = new FormData()
        if (this.processId && this.processId.id) {
          formData.append('process_id', this.processId.id)
        }
        if (this.positionId && this.positionId.id) {
          formData.append('position_id', this.positionId.id)
        }
        formData.append('name', this.name)
        formData.append('description', this.description)
        if (this.file && this.file !== '' && this.file !== undefined) {
          formData.append('file', this.file)
        }
        formData.append('fileType', 'document')
        if (this.format && this.format.id) {
          formData.append('format', this.format.id)
        }
        if (this.type && this.type.id) {
          formData.append('type', this.type.id)
        }
        formData.append('date', this.date)
        this.$axios.post('processes', formData, this.config).then(() => {
          // location.reload()
          this.getDefaultData()
          this.getProcesses()
          this.$modal.hide('crudModal')
        })
      },

      async saveItem() {
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + decryptData(this.$session.get('tokenSession'))
            : 'Bearer ',
        }
        const formData = new FormData()
        if (this.processId && this.processId.id) {
          formData.append('process_id', this.processId.id)
        }
        formData.append('position_id', this.positionId.id)
        formData.append('name', this.name)
        formData.append('description', this.description)
        if (this.file && this.file !== '' && this.file !== undefined) {
          formData.append('file', this.file)
          if (this.selected.lastContent && this.selected.lastContent.id) {
            formData.append('fileId', this.selected.lastContent.id)
          }
        }
        formData.append('fileType', 'document')
        if (this.format && this.format.id) {
          formData.append('format', this.format.id)
        }
        if (this.type && this.type.id) {
          formData.append('type', this.type.id)
        }
        formData.append('date', this.date)
        formData.append('_method', 'patch')
        this.$axios.post('processes/' + this.item.id, formData, this.config).then(() => {
          // location.reload()
          this.getDefaultData()
          this.getProcesses()
          this.$modal.hide('crudModal')
        })
      },

      async deleteItem(item) {
        this.$swal({
          title: '¿Estás seguro?',
          text:
            'Eliminarás esta información y todas sus dependencias de forma permanente',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Si, eliminar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$axios.delete('processes/' + item.id).then(() => {
              this.getDefaultData()
              this.$swal({
                title: '¡Listo!',
                text: 'El proceso fue eliminado',
                icon: 'success',
              })
            })
          }
        })
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
  .v-application--is-ltr .v-treeview-node__content {
    margin-left: 0px;
    max-width: 94%;
  }
  .process-tree {
    .v-treeview-node__root {
      padding: 0px;
      justify-content: flex-end;
    }
    .v-treeview-node__level {
      width: 15px;
    }
    .v-subheader {
      height: auto;
    }
  }
</style>
